<script>
	import GlobalVue from '@helper/Global.vue';
	import Gen from '@helper/Gen';
	export default {
		extends: GlobalVue,
		data() {
			return {
				static_content: {},
				cmp_name: "",
				cmp_address: "",
				cmp_number: "",
				sosial_media: {},
			}
		},
		computed: {
			yearcopy() {
				return Gen.yearCopy(this.web.yearcopy)
			}
		},
		mounted() {
			this.staticContent()
			this.sosialMedia()
			this.webSettings()
		},
		methods: {
			staticContent() {
				Gen.apirest("/static-content", {id:10}, (err, resp) => {
					if (err) console.log(err)
					this.static_content = resp.data
				})
			},
			sosialMedia() {
				Gen.apirest("/sosial-media", {}, (err, resp) => {
					if (err) console.log(err)
					this.sosial_media = resp.data
				})
			},
			webSettings() {
				Gen.apirest("/web-setting", {}, (err, resp) => {
					if (err) console.log(err)
					this.cmp_name = resp.data.cmp_name
					this.cmp_address = resp.data.cmp_address
					this.cmp_number = resp.data.cmp_number
				})
			},
		}
	};
</script>
<template>
	<footer id="footer" class="dark">

		<div class="container">
			<div class="footer-widgets-wrap">
				<div class="row">
					<div class="col-lg-4 col-md-5">
						<div class="widget">
							<img v-if="static_content.show_image=='Y'" :src="uploader(static_content.image1)"
								alt="Cape Discovery Logo">
							<p v-html="static_content.description"></p>
						</div>
					</div>
					<div class="col-lg-8 col-md-7">
						<div class="row">
							<div class="col-lg-4 col-md-7 col-6">
								<div class="widget widget_links">
									<h4>{{web.lbl_our_cape_discovery}}</h4>
									<ul>
										<li>
											<router-link :to="{name:'CDProses'}">{{web.lbl_the_proses}}</router-link>
										</li>
										<li>
											<router-link :to="{name:'CDCollection'}">{{web.lbl_the_collection}}
											</router-link>
										</li>
										<li>
											<router-link :to="{name:'CDHowToEnjoy'}">{{web.lbl_how_to_enjoy}}
											</router-link>
										</li>
										<li v-if="false">
											<router-link :to="{name:'Subscribe'}">{{web.lbl_subscription}}</router-link>
										</li>
									</ul>
								</div>
								<div class="widget widget_links">
									<h4>{{web.lbl_our_bellissimo}}</h4>
									<ul>
										<li>
											<router-link :to="{name:'BLProses'}">{{web.lbl_the_proses}}</router-link>
										</li>
										<li>
											<router-link :to="{name:'BLCollection'}">{{web.lbl_the_collection}}
											</router-link>
										</li>
										<li>
											<router-link :to="{name:'BLHowToEnjoy'}">{{web.lbl_how_to_enjoy}}
											</router-link>
										</li>
										<li v-if="false">
											<router-link :to="{name:'Subscribe'}">{{web.lbl_subscription}}</router-link>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-lg-3 col-md-5 col-6">
								<div class="widget widget_links">
									<h4>{{web.lbl_meet_us}}</h4>
									<ul>
										<li>
											<router-link :to="{name:'Story'}">{{web.lbl_our_story}}</router-link>
										</li>
										<li>
											<router-link :to="{name:'Blog'}">{{web.lbl_our_blog}}</router-link>
										</li>
										<li>
											<router-link :to="{name:'Cities'}">{{web.lbl_our_cities}}</router-link>
										</li>
									</ul>
								</div>
								<div class="widget widget_links">
									<h4>
										<router-link :to="{name:'ContactUs'}">{{web.lbl_talk_to_us}}</router-link>
									</h4>
								</div>
								<div v-if="false" class="widget widget_links">
									<h4>
										<router-link :to="{name:'Subscribe'}">{{web.lbl_subscribe}}</router-link>
									</h4>
								</div>
							</div>
							<div class="col-lg-5 mt-4 mt-lg-0">
								<div class="widget">
									<h4>{{cmp_name}}</h4>
									<p v-html="cmp_address"></p>
									<!-- <p>{{cmp_number}}</p> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Copyrights -->
		<div id="copyrights">
			<div class="container clearfix">
				<div class="col_full text-center social-icons">
					<a v-for="(v,k) in sosial_media" :key="k" :href="v.value" target="_blank"
						:class="'social-icon si-dark  si-small si-borderless si-rounded si-'+v.title">
						<i :class="'icon-'+v.title"></i>
						<i :class="'icon-'+v.title"></i>
					</a>
				</div>
				<div class="col_full text-center">
					<p>Copyrights &copy; {{yearcopy}} All Rights Reserved by dimatique.</p>
					<div class="copyright-links">
						<router-link :to="{name:'TermsConditions'}">{{ web.lbl_terms_of_use }}</router-link> /
						<router-link :to="{name:'PrivacyPolicy'}">{{ web.lbl_privacy_policy }}</router-link> /
						<router-link :to="{name:'PrivacyCookie'}">{{ web.lbl_cookie_policy }}</router-link>
					</div>
				</div>
			</div>
		</div><!-- #copyrights end -->
	</footer>
</template>